const brand = {
  key: 'JAARON',
  accessKey: process.env.REACT_APP_JAARON_ACCESS_KEY,
  name: 'JAARON',
  url: 'https://www.jaaron.fr/',
  features: {
    withUidCheck: false,
    withReferenceInput: true,
    withIssueRequest: true,
    withTextRecognition: false,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1699011517/certificate-request-page/brands/jaaron/Logo_JAARON_02_20221024_rkuspb.png',
    homePage:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1699011307/certificate-request-page/brands/jaaron/LP_-_page_d_accueil_q2nn8w.jpg',
    homePageCompressed: null,
    certificateExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1699276469/cdhuytcfiisg8nda8yed_c21458_.png',
    certificateExampleCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1699276597/cdhuytcfiisg8nda8yed_c21458__1_sb2blk.jpg',
    favicon:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540652/certificate-request-page/brands/brand%20z/favicon.png',
    uidExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1699011604/certificate-request-page/brands/jaaron/Etape_1_-___gr3w5i.jpg',
  },
};

export default brand;
